// const {$emit, $on, $off} = useNuxtApp()
import mitt from 'mitt'

const emitter = mitt()

export function globalEmit(event, args = null) {
    emitter.emit(event, args);
}

export function globalOn(event, callback = null) {
    if (!callback)
        callback = () => {
        }

    emitter.on(event, callback);
}

export function globalOff(event, callback) {
    emitter.off(event, callback);
}