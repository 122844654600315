<script setup>
const props = defineProps({
    type: {
        type: String,
        default: "classic",
    },
    canCreatePost: {
        type: Boolean,
        default: false,
    },
});

const emit = defineEmits(["opened"]);

function openNewPostModal() {
    globalEmit("app::openTweetEditor");
    emit("opened");
}
</script>

<template>
    <button v-if="canCreatePost && type == 'header'" class="btn btn-dark p-0 lg:p-2 w-6 h-6 lg:w-auto lg:h-auto lg:px-4" @click="openNewPostModal">
        <IconsPlusIcon/>
        <span class="hidden lg:block">New Post</span>
    </button>
    <button v-else-if="canCreatePost && type == 'mobile-header'" class="flex gap-4 items-center" @click="openNewPostModal">
        <IconsPlusIcon class="w-8 h-8"/>
        <span>New Post</span>
    </button>
    <button v-else-if="canCreatePost" class="btn bg-purple-light hover:bg-black hover:text-white hover:fill-white" @click="openNewPostModal">
        <IconsPlusIcon/>
        <span>New Post</span>
    </button>
</template>
