import axios from "axios";

export default function () {

    const config = useRuntimeConfig();
    const baseUrl = config.public.API_ENDPOINT;

    const apiClient = axios.create({
        baseURL: baseUrl,
    })

    return apiClient;
}