<script setup>
const feedbackInitialValue = "@Evangelistgg_ ";

const emit = defineEmits(["opened"]);

function openModal() {
    globalEmit('app::openTweetEditor', {
        initialValue: feedbackInitialValue,
        layout: "feedback"

    });
    emit("opened");
}

</script>

<template>
    <button class="btn btn--feedback" @click="openModal">Share Feedback</button>
</template>