import * as amplitude from '@amplitude/analytics-browser';
import { sessionReplayPlugin } from '@amplitude/plugin-session-replay-browser';

export default defineNuxtPlugin((nuxtApp) => {

    const config = useRuntimeConfig();
    const AMPLITUDE_API_KEY = config.public.AMPLITUDE_API_KEY;
    const apiKey = AMPLITUDE_API_KEY;

    const sessionReplayTracking = sessionReplayPlugin();
    amplitude.add(sessionReplayTracking);

    // amplitude.init(apiKey, {
    //     defaultTracking: {
    //         sessions: true
    //     }
    // });

    // Inject the amplitude instance into the Nuxt app context as $amplitude
    // nuxtApp.provide('amplitude', amplitude);
    return {
        provide: {
            amplitude,
        }
    }
});