<script setup>
const defaultStore = useDefaultStore()

const notificationCount = computed(() => {
    return useDefaultStore().notificationCount;
})

const canCreatePost = computed(() => {
    return useDefaultStore().user.canCreatePost;
})

const notificationCountlabel = computed(() => {
    let count = notificationCount.value;
    return count > 9 ? '9+' : count;
})

const isSticky = ref(false);

const handleScroll = () => {
    // Toggle the isSticky class when scrolled more than 10 pixels
    isSticky.value = window.scrollY > 5;
};

const mobileMenuVisible = ref(false);

function afterClick() {
    mobileMenuVisible.value = false;
}

watch(notificationCount, (newVal) => {
    setNotificationCount(newVal);
}, {
    immediate: true
})

// Set up scroll listener when the component is mounted
onMounted(() => {
    window.addEventListener('scroll', handleScroll);
});

// Clean up the scroll listener when the component is unmounted
onUnmounted(() => {
    window.removeEventListener('scroll', handleScroll);
});

</script>

<template>
    <header class="hidden lg:block py-3 header mb-0 lg:mb-8 sticky top-0 z-10 bg-bodygray" :class="{ isSticky: isSticky }">
        <div class="container flex justify-start items-center gap-4">
            <NuxtLink class="flex gap-4 items-center" to="/">
                <img class="h-6" src="/logos/logo-evangelist.svg"/>
            </NuxtLink>
            <FeedbackButton/>
            <div class="ml-auto flex gap-2 lg:gap-4 navigation items-center">
                <NuxtLink to="/" class="menu-item">
                    <IconsDashboardIcon/>
                    <span class="menu-item-label">Dashboard</span>
                </NuxtLink>
                <NuxtLink to="/campaigns" class="menu-item">
                    <IconsCampaignIcon/>
                    <span class="menu-item-label">All Campaigns</span>
                </NuxtLink>
                <span class="hidden lg:block w-px h-8 bg-black/10"></span>
                <NuxtLink to="/profile" class="menu-item menu-item-icon">
                    <IconsUserIcon/>
                </NuxtLink>
                <button class="menu-item menu-item-icon group" @click="defaultStore.openNotificationSidebar()">
                    <span v-if="notificationCount" class="flex items-center justify-center h-4 rounded-full bg-red group-hover:bg-white group-hover:text-black text-white text-xxs" :class="[notificationCount < 10 ? 'w-4' : 'px-1' ]">
                        <span class="relative" style="top: 0.5px">{{ notificationCountlabel }}</span>
                    </span>
                    <IconsNotificationIcon v-else/>
                </button>
                <NewPostButton type="header" :canCreatePost="canCreatePost"/>
            </div>
        </div>
    </header>
    <header class="block lg:hidden py-3 header mb-0 lg:mb-8 sticky top-0 z-10 bg-bodygray" :class="{ isSticky: isSticky }">
        <div class="container flex justify-start items-center gap-4">
            <NuxtLink class="flex gap-4 items-center" to="/">
                <img class="h-6" src="/logos/logo-evangelist.svg"/>
            </NuxtLink>
            <div class="ml-auto flex gap-2 lg:gap-4 navigation items-center">
                <NewPostButton type="header" :canCreatePost="canCreatePost" @opened="afterClick"/>
                <button class="menu-item menu-item-icon group" @click="defaultStore.openNotificationSidebar()">
                    <span v-if="notificationCount" class="flex items-center justify-center h-4 rounded-full bg-red group-hover:bg-white group-hover:text-black text-white text-xxs" :class="[notificationCount < 10 ? 'w-4' : 'px-1' ]">
                        <span class="relative" style="top: 0.5px">{{ notificationCountlabel }}</span>
                    </span>
                    <IconsNotificationIcon v-else/>
                </button>
                <button @click="mobileMenuVisible = !mobileMenuVisible">
                    <IconsMenuIcon/>
                </button>
            </div>
        </div>
    </header>
    <transition name="slide">
        <div v-if="mobileMenuVisible" class="absolute left-0 w-full fixed-fullscreen z-50 bg-bodygray js-freeze-body">

            <div class="container flex justify-between items-center gap-4 py-3">
                <img class="h-6" src="/logos/logo-evangelist.svg"/>
                <div class="sidebar-close z-20" @click="mobileMenuVisible = false">
                    <IconsCloseIcon/>
                </div>
            </div>

            <div class="text-xl px-6 py-4 flex flex-col gap-4 items-start">
                <NuxtLink to="/" class="flex gap-4 items-center" @click="afterClick">
                    <IconsDashboardIcon class="w-8 h-8"/>
                    <span class="">Dashboard</span>
                </NuxtLink>
                <NuxtLink to="/campaigns" class="flex gap-4 items-center" @click="afterClick">
                    <IconsCampaignIcon class="w-8 h-8"/>
                    <span class="">All Campaigns</span>
                </NuxtLink>
                <NuxtLink to="/profile" class="flex gap-4 items-center" @click="afterClick">
                    <IconsUserIcon class="w-8 h-8"/>
                    <span class="">Profile</span>
                </NuxtLink>
                <NewPostButton type="mobile-header" :canCreatePost="canCreatePost" @opened="afterClick"/>
                <div class="mt-4">
                    <FeedbackButton @opened="afterClick"/>
                </div>
            </div>
        </div>
    </transition>
</template>