export function trackEvent(eventName, eventProperties = {}) {

    // console.info("trackEvent", eventName, eventProperties);
    console.info("amplitude :: trackEvent", eventName, eventProperties);

    const {$amplitude} = useNuxtApp();
    $amplitude.track(eventName, eventProperties);

}

export function trackUser(userId) {
    console.info("amplitude :: trackUser", userId ? "user-ok" : "user-ko");

    const {$amplitude} = useNuxtApp();
    $amplitude.setUserId(userId);

}

export function initializeAmplitude() {
    console.info("amplitude :: initializing");

    const publicConfig = useRuntimeConfig().public;

    if (!publicConfig.AMPLITUDE_API_KEY) {
        console.error('AMPLITUDE_API_KEY is not defined in runtime config');
        return;
    }

    console.info("amplitude :: initialized");

    const {$amplitude} = useNuxtApp();
    $amplitude.init(publicConfig.AMPLITUDE_API_KEY, {
        defaultTracking: {
            sessions: true
        }
    });

}