export default {

    getApiProxyPrefix() {
        const {API_PROXY} = useRuntimeConfig().public;
        const apiPrefix = API_PROXY;
        return apiPrefix
    },


    getTwitterLoginUrl() {
        const currentOrigin = window.location.origin;
        const callbackUrl = currentOrigin + "/";
        return usePublicAxios().get("/auth/twitter/write/login", {
            params: {
                afterLoginRedirectUrl: callbackUrl,
                onCancelLoginRedirectUrl: callbackUrl + "?login=cancelled",
            }
        });
    },

    raw_getCurrentUser() {
        return useAxios().get("/auth/profile");
    },

    getCurrentUser() {
        return this.raw_getCurrentUser();
        // return new Promise((resolve, reject) => {
        //     // Set the delay time in milliseconds
        //     const delay = 4000; // 2 seconds delay (adjust as needed)
        //     setTimeout(() => {
        //         // Call the actual API request after the delay
        //         this.raw_getCurrentUser()
        //             .then(resolve)
        //             .catch(reject);
        //     }, delay);
        // });
    },

    verifyTransaction(withdrawalId, payload = {}) {
        return useAxios().post(this.getApiProxyPrefix() + "/v1/withdrawal/"+withdrawalId+"/verify", payload);
    },

    sendTransactionCodeAgain(payload = {}) {
        return useAxios().post(this.getApiProxyPrefix() + "/v1/withdrawal/"+payload.withdrawalId+"/verify/resend");
    },

    getDashboard() {
        return useAxios().get(this.getApiProxyPrefix() + "/v1/page/dashboard");
    },

    getProfile() {
        return useAxios().get(this.getApiProxyPrefix() + "/v1/page/profile");
    },

    getCampaigns() {
        return useAxios().get(this.getApiProxyPrefix() + "/v1/page/campaigns");
    },

    getCampaign(campaignId) {
        return useAxios().get(this.getApiProxyPrefix() + "/v1/page/campaign/" + campaignId);
    },

    getTweet(tweetId) {
        return useAxios().get(this.getApiProxyPrefix() + "/v1/post-detail/" + tweetId);
    },

    submitTweet(content = "", attachments = []) {
        return useAxios().post(this.getApiProxyPrefix() + "/v1/action/post/create", {
            content,
            attachments,
        });
    },

    saveWallet(payload) {
        return useAxios().post(this.getApiProxyPrefix() + "/v1/wallet", payload);
    },

    claimTweet(tweetId) {
        return useAxios().post(this.getApiProxyPrefix() + "/v1/action/post/claim/" + tweetId);
    },

    hideTweet(tweetId) {
        return useAxios().post(this.getApiProxyPrefix() + "/v1/action/post/hide/" + tweetId);
    },

    getInvitationDetails(publicToken) {
        return useAxios().get(this.getApiProxyPrefix() + "/v1/page/invitation/token/" + publicToken);
    },

    getTransactions(currency) {
        return useAxios().get(this.getApiProxyPrefix() + "/v1/withdrawal?type=" + currency);
    },

    getWallets() {
        return useAxios().get(this.getApiProxyPrefix() + "/v1/wallet");
    },

    makeWithdraw(payload) {
        return useAxios().post(this.getApiProxyPrefix() + "/v1/withdrawal", payload);
    },

    getMarinadeLanding() {
        return useAxios().get(this.getApiProxyPrefix() + "/v1/page/home");
    },

    refreshNotifications() {
        return useAxios().get(this.getApiProxyPrefix() + "/v1/notification/recent");
    },

    markAsSeen(notificationId) {
        return useAxios().delete(this.getApiProxyPrefix() + "/v1/notification/" + notificationId);
    },

    markAllAsSeen() {
        return useAxios().delete(this.getApiProxyPrefix() + "/v1/notification");
    },

    saveBasicSettings(payload) {
        return useAxios().put(this.getApiProxyPrefix() + "/v1/user/settings/basic", payload);
    },

    disconnectWallet(walletId) {
        return useAxios().delete(this.getApiProxyPrefix() + "/v1/wallet/" + walletId);
    },

    cancelTransaction(transactionId) {
        return useAxios().delete(this.getApiProxyPrefix() + "/v1/withdrawal/" + transactionId);
    },

    loadPaychecks() {
        return useAxios().get(this.getApiProxyPrefix() + "/v1/payout/unpaid");
    },

    claimAllRewards(campaignId) {
        return useAxios().post(this.getApiProxyPrefix() + "/v1/action/payout/confirm/" + campaignId);
    },

    getWalletTypes() {
        return useAxios().get(this.getApiProxyPrefix() + "/v1/wallet/type");
    },

    setTwoFactor(payload) {
        return useAxios().put(this.getApiProxyPrefix() + "/v1/user/settings/two-factor", payload);
    },

    verifyTwoFactor(otp) {
        return useAxios().post(this.getApiProxyPrefix() + "/v1/user/settings/two-factor/approve", {otp});
    },

    setUserEmail(payload) {
        return useAxios().put(this.getApiProxyPrefix() + "/v1/user/settings/email", payload);
    },

    verifyUserEmail(otp) {
        return useAxios().post(this.getApiProxyPrefix() + "/v1/user/settings/email/approve", {otp});
    },

}