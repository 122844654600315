import {NotificationType} from "~/common/Enums.ts";

const defaultUserState = {
    userId: null,
    profileImageUrl: null,
    twitterHandle: false,
    userName: false,
    userRoles: [],
    twoFactorAuthEnabled: false,
    verifiedTwoFactorAuthEnabled: false,
    email: null,
    verifiedEmail: null,
}

const allowedEventTypes = [
    NotificationType.WELCOME,
    NotificationType.CAMPAIGN_JOINED,
    NotificationType.CAMPAIGN_ENDED,
    NotificationType.CAMPAIGN_MATURITY,
    NotificationType.NEW_ELIGIBLE_POSTS,
    // NotificationType.NEW_REWARDED_POSTS,
    NotificationType.TRANSACTION_VERIFYING,
    NotificationType.TRANSACTION_COMPLETED,
    NotificationType.TERMS_CONDITIONS,
]

export const useDefaultStore = defineStore('default', {

    state: () => ({
        user: {
            ...defaultUserState
        },
        loginLoading: false,
        notifications: [],
        notificationSidebarOpen: false,
        paychecks: []
    }),
    actions: {

        openNotificationSidebar() {
            this.notificationSidebarOpen = true;
        },

        closeNotificationSidebar() {
            this.notificationSidebarOpen = false;
        },

        loadUser() {
            let response = apiService.getCurrentUser();

            response.then(res => {
                const user = res.data;
                this.user = user;
                trackUser(user.userId);
            // }).catch(e => {
            //     errorMessage("Error! Can not get current user.")
            });

            return response;
        },

        refreshNotifications() {
            let response = apiService.refreshNotifications();

            response.then(res => {
                let notifications = res.data;
                notifications = notifications.filter(i => allowedEventTypes.includes(i.eventType));

                this.notifications = notifications;
            }).catch(e => {
                errorMessage("Error! Can not get notifications.")
            });

            return response;
        },

        clearUser() {
            this.user = defaultUserState;
        },

        loadPaychecks() {
            let response = apiService.loadPaychecks();

            response.then(res => {
                const paycheks = res.data;
                this.paychecks = paycheks;
            }).catch(e => {
                errorMessage("Error! Can not get paycheks.")
            });

            return response;

        }

    },

    getters: {

        computedNotifications() {
            return this.notifications.filter(i => !["welcome"].includes(i.eventType));
        },

        notificationCount() {
            return (this.computedNotifications ?? []).filter(i => !i.isRead).length;
        },

        ftuNotification() {
            return (this.notifications ?? []).find(i => !i.isRead && i.eventType == "welcome");
        },
    }

})