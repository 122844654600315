<script setup>
const publicConfig = useRuntimeConfig().public;

const currentDomain = computed(() => {
    return window.location.origin;
});

const isGuestDomain = computed(() => {
    return publicConfig.GUEST_DOMAINS.includes(currentDomain.value);
});

useBodyFreeze()
useFixedFullscreen();
</script>
<template>
    <div class="font-semibold">
        <LazyMarinadeLanding v-if="isGuestDomain"/>
        <NuxtLayout v-else>
            <NuxtPage/>
        </NuxtLayout>
        <CookieBar v-if="isGuestDomain"/>
    </div>
</template>
