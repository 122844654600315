export function setSeoMeta({title = null, description = null, image = null}) {
    let head = {}

    if (title) {
        head.title = title
    }

    if (description) {
        head.description = description
    }

    if (image) {
        head.image = image
    }

    useSeoStore().setSeoData(head);
}

export function setNotificationCount(count) {
    useSeoStore().setNotificationCount(count);
}